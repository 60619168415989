<template>
  <div class="manage-users">
    <h2>{{ $t("manageUsers") }}</h2>
    <v-data-table
      :headers="headers"
      :items="data"
      :search="search"
      multi-sort
      class="mt-8 elevation-0"
    >
      <!-- Top Toolbar -->
      <template v-slot:top>
        <v-toolbar flat class="data-table-top-layout">
          <!-- Create User Dialog -->
          <v-dialog
            v-model="formDialog"
            persistent
            max-width="500px"
            @click:outside="closeDialogs"
          >
            <template v-slot:activator="{ on }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  color="white--text"
                  v-on="on"
                  style="text-transform: unset !important"
                  :style="{ 'background-color': hover ? '#002147' : '#535353' }"
                  >{{ $t("createUser") }}</v-btn
                >
              </v-hover>
            </template>
            <v-card>
              <v-container class="pa-4">
                <v-row class="mb-4">
                  <v-card-title class="text-h5">{{ formTitle }} </v-card-title>
                  <v-spacer />
                  <v-btn
                    icon
                    color="primary"
                    class="mt-3"
                    @click="closeDialogs"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-row>
                <v-divider />
                <v-form @submit.prevent="formSubmit" class="mx-2">
                  <v-row>
                    <v-col cols="12" class="form-layout">
                      <a-select
                        v-model="$v.formInputCompany.$model"
                        :validator="$v.formInputCompany"
                        :items="companies"
                        itemtext="name"
                        :label="$t('selectCompany') + $t('required')"
                        class="mt-4"
                      >
                      </a-select>
                      <a-text-field
                        v-model="$v.formInputEmail.$model"
                        :validator="$v.formInputEmail"
                        type="email"
                        :label="$t('email') + $t('required')"
                         class="mt-4"
                      />
                      <a-text-field
                        v-model="$v.formInputFirstName.$model"
                        :validator="$v.formInputFirstName"
                        :label="$t('firstName') + $t('required')"
                         class="mt-4"
                      />
                      <a-text-field
                        v-model="$v.formInputLastName.$model"
                        :validator="$v.formInputLastName"
                        :label="$t('lastName') + $t('required')"
                         class="mt-4"
                      />
                      <a-select
                        v-model="$v.formInputUserType.$model"
                        :validator="$v.formInputUserType"
                        :items="userTypes"
                        :label="$t('selectType') + $t('required')"
                         class="mt-4"
                      >
                      </a-select>
                    </v-col>
                  </v-row>
                  <v-card-actions class="mt-4 mr-n4">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="closeDialogs"
                      style="text-transform: unset !important"
                    >
                     {{$t('close')}}
                    </v-btn>
                    <v-btn
                      color="primary"
                      type="submit"
                      class="mr-2"
                      style="text-transform: unset !important"
                    >
                      {{$t('save')}}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-container>
            </v-card>
          </v-dialog>
          <!-- !Create User Dialog -->
          <v-spacer />
          <v-col cols="3">
            <a-text-field
              v-model="search"
              :label="$t('search')"
              append-icon="mdi-magnify"
            ></a-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <!-- !Top Toolbar -->
      <!-- Customize Columns -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="data-table-actions-layout">
          <v-btn
            small
            width="85%"
            color="primary"
            class="mt-2 ml-1"
            style="text-transform: unset !important"
            @click.stop="associateItem(item)"
          >
            <v-icon small left>mdi-store-plus</v-icon>
            {{$t('associateStores')}}
          </v-btn>
          <v-hover v-slot="{ hover }">
            <v-btn
              small
              width="40%"
              color="white--text"
              class="ml-1 mr-2 mt-2 mb-2 px-4"
              :style="{ 'background-color': hover ? '#002147' : '#535353' }"
              style="text-transform: unset !important"
              @click.stop="editItem(item)"
            >
              <v-icon small left>mdi-pencil</v-icon>
              {{$t('edit')}}
            </v-btn>
          </v-hover>
          <v-btn
            small
            width="40%"
            class="mt-2 mb-2 px-4 ml-1"
            color="red lighten-1 white--text"
            style="text-transform: unset !important"
            @click.stop="deleteItem(item)"
          >
            <v-icon small left>mdi-delete</v-icon>
            {{$t('delete')}}
          </v-btn>
        </div>
      </template>
      <!-- !Customize Columns -->
    </v-data-table>
    <!-- Delete Item Dialog -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="500px"
      @click:outside="closeDialogs"
    >
      <v-card>
        <v-container class="pa-4">
          <v-row class="mb-4">
            <v-card-title class="text-h5">{{$t('delete')}}</v-card-title>
            <v-spacer />
            <v-btn icon color="primary" class="mt-3" @click="closeDialogs">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-divider />
          <div class="mx-4">
            <p class="my-6">{{$t('confirmUserDelete')}}</p>
            <div class="form-layout mx-2">
              <v-row>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('company')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formCompanyName }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('email')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputEmail }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('firstName')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputFirstName }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('lastName')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputLastName }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('type')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputUserType }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <v-card-actions class="mt-4 mr-n4">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="closeDialogs"
                style="text-transform: unset !important"
              >
                {{$t('close')}}
              </v-btn>
              <v-btn
                color="red lighten-1 white--text"
                style="text-transform: unset !important"
                class="mr-2"
                @click="deleteItemConfirm"
              >
                {{$t('delete')}}
              </v-btn>
            </v-card-actions>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- !Delete Item Dialog -->
    <!-- Associate Stores Item Dialog -->
    <v-dialog
      v-model="associateStoresDialog"
      persistent
      max-width="500px"
      @click:outside="closeDialogs"
    >
      <v-card>
        <v-container class="pa-4">
          <v-row class="mb-4">
            <v-card-title class="text-h5">{{$t('associateStores')}}</v-card-title>
            <v-spacer />
            <v-btn icon color="primary" class="mt-3" @click="closeDialogs">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-divider />
          <div class="mx-4">
            <div class="form-layout mx-2">
              <v-row>
                <v-col cols="4" class="mt-4">
                  <span class="dialog-content-title"> {{$t('company')}} : </span>
                </v-col>
                <v-col cols="8" class="mt-4">
                  <span class="dialog-content-text">
                    {{ formCompanyName }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('email')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputEmail }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('firstName')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputFirstName }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('lastName')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputLastName }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('type')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputUserType }}
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    v-model="formInputStores"
                    :items="stores"
                    item-text="name"
                    chips
                    clearable
                    :label="$t('selectStores')"
                    multiple
                    solo
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="remove(item)"
                      >
                        <strong>{{ item.name }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </div>
            <v-card-actions class="mt-4 mr-n4">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="closeDialogs"
                style="text-transform: unset !important"
              >
                {{$t('close')}}
              </v-btn>
              <v-btn
                color="primary"
                @click="associateStoresItem"
                class="mr-2"
                style="text-transform: unset !important"
              >
                {{$t('save')}}
              </v-btn>
            </v-card-actions>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- !Associate Stores Item Dialog -->
  </div>
</template>
<script>
import AppError from "@/utils/AppError.js";
import ATextField from "@/components/ATextField.vue";
import ASelect from "@/components/ASelect.vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "manage-users",

  components: {
    ATextField,
    ASelect,
  },

  mounted: function () {
    this.loadData();
    this.loadCompanies();
  },

  mixins: [validationMixin],
  validations: {
    // Form validations
    formInputCompany: { required },
    formInputFirstName: { required },
    formInputLastName: { required },
    formInputEmail: { required, email },
    formInputUserType: { required },
  },

  data() {
    return {
      // Dialogs
      formDialog: false,
      deleteDialog: false,
      associateStoresDialog: false,

      // Data table
      search: null,
      selectedIndex: -1,

      // Data
      data: [],
      formInputStores: [],
      stores: [],
      companies: [],

      // Form data
      formInputFirstName: null,
      formInputLastName: null,
      formInputEmail: null,
      formInputUserType: null,
      formInputCompany: null,
      formCompanyName: null,
    };
  },

  computed: {
    // Return the form title depending on the index.
    formTitle() {
      return this.selectedIndex === -1
        ? this.$t("createUser")
        : this.$t("editUser");
    },
    // User types
    userTypes() {
      return ["admin", "manager", "regular"];
    },
    headers() {
      return [
        { text: this.$t('company') , value: "company.name" },
        { text: this.$t('email'), value: "email" },
        {
          text: this.$t('firstName'),
          value: "first_name",
        },
        {
          text: this.$t('lastName'),
          value: "last_name",
        },
        {
          text: this.$t('type'),
          value: "user_type.name",
        },
        {
          text: this.$t('actions'),
          value: "actions",
          align: "center",
          filterable: false,
          sortable: false,
          width: 300,
        },
      ];
    },
  },

  methods: {
    /**
     * Remove chip from combobox.
     * @param {store} item - the store to remove.
     * @public
     */
    remove(item) {
      this.formInputStores.splice(this.formInputStores.indexOf(item), 1);
      this.formInputStores = [...this.formInputStores];
    },
    /**
     * Get the users.
     * @public
     */
    loadData() {
      // Load form data
      const loadFormData = this.$api({
        url: this.$config.api.route.users,
        method: "GET",
      }).then((resp) => {
        this.data = resp.data;
      });
      // Async handle loads
      this.$store.dispatch("util/startLoading");
      Promise.all([loadFormData])
        .catch(() => {
          AppError.handle(new AppError("LOAD_DATA_UNKNOWN"));
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get the companies.
     * @public
     */
    loadCompanies() {
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: this.$config.api.route.companies,
        method: "GET",
      })
        .then((resp) => {
          this.companies = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Set the associate stores dialog.
     * @param {user} item - the user.
     * @public
     */
    associateItem(item) {
      this.selectedIndex = this.data.indexOf(item);
      this.associateStoresDialog = true;
      this.getUserStores(item);
      this.getCompanyStores(item.company);
      this.setForm(item);
    },
    /**
     * Get the company stores.
     * @param {company} item - the company.
     * @public
     */
    getCompanyStores(company) {
      let url = this.$config.api.route.companiesStores;
      url = url.replace(":id", company.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.stores = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Set the edit dialog.
     * @param {user} item - the user.
     * @public
     */
    editItem(item) {
      this.selectedIndex = this.data.indexOf(item);
      this.setForm(item);
      this.formDialog = true;
    },
    /**
     * Set the delete dialog.
     * @param {user} item - the user.
     * @public
     */
    deleteItem(item) {
      this.selectedIndex = this.data.indexOf(item);
      this.setForm(item);
      this.deleteDialog = true;
    },
    /**
     * Close all the dialogs.
     * @public
     */
    closeDialogs() {
      this.formDialog = false;
      this.deleteDialog = false;
      this.associateStoresDialog = false;
    },
     resetInputFields(){
      this.$nextTick(() => {
        this.resetForm();
        this.selectedIndex = -1;
      });
    },
    /**
     * Set the user details.
     * @param {user} item - the user.
     * @public
     */
    setForm(item) {
      let company = this.companies.find(
        (company) => company.name === item.company.name
      );
      this.formInputCompany = company;
      this.formCompanyName = this.formInputCompany.name;
      this.formInputFirstName = item.first_name;
      this.formInputLastName = item.last_name;
      this.formInputEmail = item.email;
      var typeIndex = this.userTypes.indexOf(item.user_type.name);
      this.formInputUserType = this.userTypes[typeIndex];
      this.formInputStores = item.stores;
    },
    /**
     * Reset the form dialog.
     * @public
     */
    resetForm() {
      this.formInputCompany = null;
      this.formInputFirstName = null;
      this.formInputLastName = null;
      this.formInputEmail = null;
      this.formInputUserType = null;
      this.formInputStores = null;
      this.stores = null;
      this.$v.$reset();
    },
    /**
     * Create or edit a user.
     * @public
     */
    formSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      // Get the user type based on the index
      let typeIndex = this.userTypes.indexOf(this.formInputUserType);
      let typeId = typeIndex + 1;

      let company = null;

      if (typeof this.formInputCompany === "object") {
        company = this.companies.find(
          (company) => company.name === this.formInputCompany.name
        );
      } else {
        company = this.companies.find(
          (company) => company.name === this.formInputCompany
        );
      }

      const item = this.data[this.selectedIndex];
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.users;
      if (item !== undefined) {
        url = this.$config.api.route.usersAction;
        url = url.replace(":id", item.id);
      }

      this.closeDialogs();

      this.$api({
        url: url,
        data: {
          company: company.id,
          email: this.formInputEmail,
          first_name: this.formInputFirstName,
          last_name: this.formInputLastName,
          user_type: typeId,
        },
        method: item === undefined ? "POST" : "PUT",
      })
        .then(() => {
          
          this. resetInputFields();
          this.loadData();
          this.$store.dispatch(
            "alert/showSuccess",
            item === undefined
              ? this.$t('userCreated')
              : this.$t('userEdited')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Delete user.
     * @public
     */
    deleteItemConfirm() {
      const item = this.data[this.selectedIndex];

      this.closeDialogs();

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.usersAction;
      url = url.replace(":id", item.id);
      this.$api({
        url: url,
        method: "DELETE",
      })
        .then(() => {
          
          this.resetInputFields();
          this.loadData();
          this.$store.dispatch(
            "alert/showSuccess",
            this.$t('userDeleted')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Associate stores to user.
     * @public
     */
    associateStoresItem() {
      var itemStoresIds = [];
      this.formInputStores.forEach((s) => {
        let store = this.stores.find((store) => store.name === s.name);

        itemStoresIds.push(store.id);
      });

      this.closeDialogs();

      const item = this.data[this.selectedIndex];
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.usersStores;
      url = url.replace(":id", item.id);
      this.$api({
        url: url,
        data: {
          storeIds: itemStoresIds,
        },
        method: "POST",
      })
        .then(() => {
          
          this.resetInputFields();
          this.loadData();
          this.$store.dispatch(
            "alert/showSuccess",
             this.$t('storesAssociated')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get user stores.
     * @param {user} item - the user.
     * @public
     */
    getUserStores(item) {
      let url = this.$config.api.route.usersStores;
      url = url.replace(":id", item.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          item.stores = resp.data;
          this.formInputStores = item.stores;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
  },
};
</script>
